import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
// import photo from "../../assets/Rectangle 34624653.png";

const MeaningCard = ({ title, colors, subTitle, UnOrderList1, img }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  return (
    <>
      <MeaningCards>
        <div style={{ display: "flex", flexDirection: "row", gap: "7.6vw" }}>
          <img
            src={img}
            style={{ width: "14vw", background: "none" }}
            alt="img"
          />
          <Content>
            <div>
              <MeaningHeading color={colors}>{title}</MeaningHeading>
              <MeaningPara>{subTitle}</MeaningPara>
            </div>
            <UnOrderList>
              {UnOrderList1?.map((list, index) => (
                <List key={index}>{list}</List>
              ))}
            </UnOrderList>
          </Content>
        </div>
      </MeaningCards>
    </>
  );
};

export default MeaningCard;

const MeaningCards = styled(motion.div)`
  width: 56.37vw;
  /* background: none; */
  /* margin-right: 215px; */
  /* height: 60vh; */
  position: relative;
  z-index: 1;
  border: 0.1rem solid ${(props) => props.theme.colors.secondary};
  border-radius: 3.3vw;
  padding: 2vh 4vw 2.33vh 5.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Content = styled.div`
  padding-top: 3.2vh;
  gap: 2.5vh;
  padding-bottom: 0.5vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;
const MeaningHeading = styled.h2`
  font-size: 3.33vw;
  font-weight: 700;
  color: ${(props) => props.color};
  margin-bottom: 2.69vh;
`;
const MeaningPara = styled.h3`
  font-size: 1.56vw;
  width: 24.3vw;
  font-weight: 500;
  /* margin-bottom: 2.6vh; */
`;
const UnOrderList = styled.ul`
  width: 24vw;
`;
const List = styled.li`
  font-size: 1.24vw;
  margin-bottom: 3vh;
  font-weight: 500;
`;
