import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { data } from "../assets/MeaningMokeups";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MeaningCard from "../components/Reusable/MeaningCard";

const isMac = () => {
  const platform = navigator.platform.toUpperCase();
  return (
    platform.indexOf("MAC") >= 0 ||
    platform.indexOf("IPHONE") >= 0 ||
    platform.indexOf("SAFARI") >= 0
  );
};

const getImage = (item) => {
  if (isMac()) {
    return item?.macImg;
  } else {
    return item?.img;
  }
};

const MeaningPage = () => {
  const scrollWrapperRef = useRef(null);
  const scrollContainerRef = useRef(null);

  useLayoutEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;
    const scrollContainer = scrollContainerRef.current;

    if (!scrollWrapper || !scrollContainer) return;

    const getScrollAmount = () => {
      let containerWidth = scrollWrapper.scrollWidth;
      return -(containerWidth - window.innerWidth);
    };

    const tween = gsap.to(scrollContainer, {
      x: getScrollAmount,
      duration: 4,
      ease: "none",
    });

    ScrollTrigger.create({
      trigger: ".racesWrapper",
      start: "top",
      end: () => `+=${getScrollAmount() * -1}`,
      pin: true,
      animation: tween,
      scrub: 1,
      invalidateOnRefresh: true,
    });

    return () => {
      tween.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <MainScroll className="scroll-meaning racesWrapper" ref={scrollWrapperRef}>
      <ScrollContainer
        className="scroll-container races"
        ref={scrollContainerRef}
      >
        {data?.map((data, index) => (
          <MeaningCard
            img={getImage(data)}
            key={index}
            // img={data?.img}
            title={data?.title}
            subTitle={data?.subTitle}
            UnOrderList1={data?.UnOrderList}
            colors={data?.color}
          />
        ))}
        <ExtraSpace></ExtraSpace>
      </ScrollContainer>
    </MainScroll>
  );
};

export default MeaningPage;

const ExtraSpace = styled.div`
  width: 58vw;
  background: none;
  padding-left: 9vw;
  padding-bottom: 9vw;
  padding-top: 9vw;
  padding-right: 9vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ScrollContainer = styled.div`
  display: flex;
  background: none;
  align-items: flex-start;
  flex-direction: row;
  width: fit-content;
  gap: 3.64vw;
  width: auto;
  position: relative;
  z-index: 1;
  padding-left: 11vw;
  margin-bottom: 6.3vh;
  height: auto;
`;

const MainScroll = styled.div`
  height: 100vh;
  padding-left: 11vw;
  /* background: none; */
  position: relative;
  z-index: 2;
  background: none;
  margin: 0px;
  overflow-x: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
