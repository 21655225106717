import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Img1 } from "../assets/assets";
import logo from "../assets/webIcon.png";
import logo2 from "../assets/webIcon2.png";
import { motion, useInView, useAnimation } from "framer-motion";
import ScrollDown from "../components/Reusable/ScrollDown";

const HomePage = () => {
  const Homeref = useRef(null); // useRef for animation trigger on scroll
  const isInView = useInView(Homeref, { once: false }); // function of  framer motion when we scroll our page or only animation run when we view the page
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible"); //mainControl also function of framer motion
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  //create variants for type of animation we perform
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, delayChildren: 0.5, staggerChildren: 0.2 },
    },
  };

  //diffrent varients for diffrent div or text
  const text1Variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const text2Variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, x: -150 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const imgVariants = {
    hidden: { opacity: 0, x: 200 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  return (
    <MainContainer
      ref={Homeref}
      variants={containerVariants}
      initial="hidden"
      animate={mainControls}
    >
      <HeadContainer>
        <LogoDiv variants={text1Variants}>
          <img width="50vw" src={logo} alt="shloka" />
          <img height="32vh" src={logo2} alt="shloka" />
          {/* <Text1 >HLOKA</Text1> */}
        </LogoDiv>
        <Text1 variants={text1Variants}>A world with Quotes</Text1>
        <Text2 variants={text2Variants}>
          Get Learnings and Meaning from the Shloks on daily base to improve you
          daily
        </Text2>
        <Btn1 variants={buttonVariants}>Get App</Btn1>
      </HeadContainer>
      <ImgContainer variants={imgVariants}>
        <Img1 width="13.90vw" />
      </ImgContainer>
      <ScrollDown />
    </MainContainer>
  );
};

export default HomePage;
const LogoDiv = styled(motion.div)`
  display: flex;
  align-items: flex-end;
`;

const MainContainer = styled(motion.div)`
  height: 100vh;
  position: relative;
  background: none;
  /* position: relative; */
  z-index: 1;
  padding: 16.11vh 11.19vw 18.39vh 11.19vw;
  display: flex;
  align-items: flex-end;
  gap: 4vw;
  justify-content: center;
`;
const HeadContainer = styled.div`
  width: auto;
  background: none;
`;
const Text1 = styled(motion.h1)`
  line-height: normal;
  font-size: 3.32vw;
  /* font-family: bellota Text; */
  background: none;
  font-weight: 600;
`;
const Text2 = styled(motion.h2)`
  width: 32vw;
  text-align: justify;
  margin-top: 5.1vh;
  background: none;
  font-size: 1.7vw;
  font-weight: 600;
`;
const Btn1 = styled(motion.button)`
  border: none;
  margin-top: 4.3vh;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 1.4vh 2.08vw;
  font-size: 1.5vw;
  font-weight: 500;
  border-radius: 0.62vw;
`;
const ImgContainer = styled(motion.div)``;
