import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import ScrollTrigger from "react-scroll-trigger";

let Scroll = keyframes`
        0%{
            opacity: 0;
            transform: rotate(45deg) translate(-20px,-20px);
        }
        50%{
   opacity: 1;
        }
        
        100%{
  opacity: 0;
  transform: rotate(45deg) translate(20px,20px);
        }
    `;
const ScrollDown = () => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      {counterOn && (
        <Arrow>
          <ArrowChild></ArrowChild>
          <ArrowChild></ArrowChild>
          <ArrowChild></ArrowChild>
        </Arrow>
      )}
    </ScrollTrigger>
  );
};

export default ScrollDown;

const Arrow = styled.div`
  background: none;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ArrowChild = styled.span`
  background: none;
  display: block;
  width: 1vw;
  height: 1vw;
  border-bottom: 0.1vw solid ${(props) => props.theme.colors.secondary};
  border-right: 0.1vw solid ${(props) => props.theme.colors.secondary};
  transform: rotate(45deg);
  margin: -0.3vh -0.2vw;
  ${(props) =>
    css`
      animation: ${Scroll} 2s infinite;
    `}

  &:nth-child(2) {
    animation-delay: -0.2s;
  }
  &:nth-child(3) {
    animation-delay: -0.2s;
  }
`;
