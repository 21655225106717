import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, useInView, useAnimation } from "framer-motion";

const TermAndCondtion = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const Homeref = useRef(null); // useRef for animation trigger on scroll
  const isInView = useInView(Homeref, { once: false }); // function of  framer motion when we scroll our page or only animation run when we view the page
  const mainControls = useAnimation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible"); //mainControl also function of framer motion
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  const text1Variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const text2Variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, delayChildren: 0.5, staggerChildren: 0.2 },
    },
  };
  return (
    <Container
      ref={Homeref}
      // variants={containerVariants}
      initial="hidden"
      animate={mainControls}
    >
      <HelmetWrapper>
        <title>Gurucool ||Privacy Policy</title>
        <meta
          name="description"
          content="GuruCool prioritizes your privacy. Read our comprehensive privacy policy to understand how we collect, use, and protect your personal information. Your data security is our top concern."
        />
      </HelmetWrapper>

      <MainHeading variants={text1Variants}>Terms & Conditions</MainHeading>
      <SubHeading variants={text1Variants}>
        PLEASE READ THIS IMPORTANT DISCLAIMER CAREFULLY:
      </SubHeading>
      <StyledBox>
        <Text variants={text2Variants}>
          THE SERVICES ARE PROVIDED TO YOU AT YOUR OWN RISK. YOU ARE USING THE
          SERVICES FOR OBTAINING ASTROLOGICAL CONSULTATIONS ONLY. THE ASTROLOGY
          PARTNER MAY PROVIDE SUCH CONSULTATION BY USAGE OF VEDIC ASTROLOGY,
          TAROT READING, VAASTU, NUMEROLOGY, WESTERN ASTROLOGY OR ANY OTHER SUCH
          METHOD THAT MAY BE INFORMED TO YOU. YOU AGREE THAT ASTROLOGICAL
          CONSULTATION IS INHERENTLY SUBJECTIVE IN NATURE AND DIFFERENT
          ASTROLOGERS MAY ARRIVE AT VARYING OR EVEN CONFLICTING RESULTS, DESPITE
          FOLLOWING SIMILAR METHODS. THE COMPANY, ASTROLOGER PARTNERS AND THEIR
          RESPECTIVE AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND
          LICENSORS HEREBY EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, ASSURANCES
          OR REPRESENTATIONS AS TO THE CORRECTNESS OR ACCURACY OF ANY ADVICE,
          PREDICTION OR REMEDY PROVIDED THROUGH THE SERVICES. YOU HEREBY AGREE
          AND ACKNOWLEDGE THAT THE SERVICES ARE NOT INTENDED AS A SUBSTITUTE FOR
          OR AS COMPLEMENTARY TO ACTUAL MEDICAL, LEGAL OR OTHER SUCH
          PROFESSIONAL ADVICE THAT YOU MAY OBTAIN.
        </Text>
      </StyledBox>
      <GeneraHeading variants={text1Variants}>I. GENERAL</GeneraHeading>
      <Box2 variants={text2Variants}>
        <Box2Text>
          1. These terms and conditions for use of Gurucool and provision of
          associated web services (“Terms”) together with the accompanying
          schedule containing the refund policy, shall govern the access or use
          by you of Gurucool Astro Mobile Application, website, content,
          products, and services provided in relation to it (“Services”)
          including by way of mobile application and associated web and
          teleservices made available.
        </Box2Text>
        <Box2Text>
          2. Your access and use of Services constitutes your agreement to be
          bound by these Terms, which establishes a contractual relationship
          between you and the Company. If you do not agree with these Terms, you
          may not access or use the Services. These Terms expressly supersede
          all prior agreements or arrangements with you.
        </Box2Text>
        <Box2Text>
          3. The Company may immediately and without notice terminate these
          Terms or your access and/or use of any Services with respect to you,
          at any time for any reason. Supplemental terms may apply to certain
          Services, such as policies for a particular event, activity, or
          promotion, and such supplementary terms will be disclosed to you at
          the time of provision of such Services. All supplementary terms shall
          be in addition to, and shall be deemed a part of the Terms as far as
          it relates to provision of such Services. In the event of a conflict
          between these Terms and supplementary terms, the supplementary terms
          shall prevail.
        </Box2Text>
        <Box2Text>
          4. The Company may amend the Terms related to the Services from time
          to time. Such amendments shall be effective upon the Company’s posting
          of such updated Terms at this location or the amended policies or
          supplemental terms on the applicable Service. Your continued access or
          use of the Services after such posting constitutes your consent to be
          bound by such amended Terms.
        </Box2Text>
        <Box2Text>
          5. Failure of the Company to insist, in any one or more instances, on
          your performance of any of your obligations under these Terms shall
          not be construed as a waiver or relinquishment of any of the Company’s
          rights, and your obligations hereunder shall continue to remain in
          full force and effect.
        </Box2Text>
      </Box2>
      <StyledHeading variants={text1Variants}>II. SERVICES</StyledHeading>
      <Box3 variants={text2Variants}>
        <Box3Text>
          6. The Services constitute a web-based technology platform that
          enables users of the Company’s mobile application, or websites
          provided as part of the Services (each, an “Application”), to obtain
          astrological consultation from independent third party astrologers
          under agreement with the Company (“Astrologer Partners”). You
          acknowledge that the Company does not provide any astrological
          consultation, and all such consultations are provided by independent
          third-party contractors, who are not employed by the Company or its
          affiliates.
        </Box3Text>
        <Box3Text>
          7. The Services are made available solely for your personal,
          non-commercial use. Subject to your compliance with these Terms, the
          Company hereby grants you a limited, non-exclusive, non-sublicensable,
          revocable, non-transferable license to: (i) access and use the
          Applications on your personal device solely in connection with your
          use of the Services; and (ii) access and use any content, information
          and related materials that may be made available through the Services,
          in each case solely for your personal, non-commercial use. Any rights
          not expressly granted herein are reserved by the Company and its
          licensors.
        </Box3Text>
        <Box3Text>
          8. You may not: (i) remove any copyright, trademark or other
          proprietary notices from any portion of the Services; (ii) reproduce,
          modify, prepare derivative works based upon, distribute, license,
          lease, sell, resell, transfer, publicly display, publicly perform,
          transmit, record, stream, broadcast or otherwise exploit the Services,
          except as expressly permitted by the Company; (iii) decompile, reverse
          engineer or disassemble the Services except as may be permitted by
          applicable law; (iv) link to, mirror or frame any portion of the
          Services; (v) cause or launch any programs or scripts for the purpose
          of scraping, indexing, surveying, or otherwise data mining any portion
          of the Services or unduly burdening or hindering the operation and/or
          functionality of any aspect of the Services; or (vi) attempt to gain
          unauthorized access to or impair any aspect of the Services or its
          related systems or networks.
        </Box3Text>
        <Box3Text>
          9. The Services may be made available or accessed in connection with
          third-party services and content (including advertisement) that the
          Company does not control. You acknowledge that different terms of use
          and privacy policies may apply to your use of such third-party
          services and content. The Company does not endorse such third-party
          services and content and in no event shall be responsible or liable
          for any products or services of such third-party providers.
        </Box3Text>
        <Box3Text>
          10. The Services and all rights therein are and shall remain the
          Company’s property or the property of the Company’s licensors. Neither
          these Terms nor your use of the Services convey or grant to you any
          rights: (i) in or related to the Services except for the limited
          license granted above; or (ii) to use or reference in any manner the
          Company’s names, logos, product and service names, trademarks or
          services marks or those of the Company’s licensors.
        </Box3Text>
        <Box3Text>
          11. In order to use most aspects of the Services, you must register
          for and maintain an active personal user account (“Account”). You must
          be at least 18 years of age to obtain an Account. Registration for the
          Account may require you to submit to the Company certain personal
          information, such as your name, place of birth, date of birth, time of
          birth, and mobile phone number. Additionally, you may be required to
          submit payment-related information. You may also create an Account by
          using third-party services such as Facebook, Google, or Apple. In the
          event the Account is created by using third-party services, you hereby
          authorize the Company to access and obtain all relevant data available
          thereon. You further agree that you shall be responsible to ensure the
          security of such a third-party account, and any breach of such
          security may adversely affect your Account and the Services, for which
          the Company shall not be liable. You may not create, possess, or
          operate more than one Account at any given point in time.
        </Box3Text>
        <Box3Text>
          12. You agree to maintain accurate, complete, and up-to-date
          information in your Account. Your failure to maintain accurate,
          complete, and up-to-date information in your Account may adversely
          affect the provision of Services. You are responsible for all activity
          that occurs under your Account, and you agree to maintain the security
          and secrecy of your Account username and password at all times. Unless
          otherwise permitted by the Company, you may only possess one Account.
        </Box3Text>
        <Box3Text>
          13. The Service is not available for use by persons under the age of
          18. You may not authorize third parties to use your Account, and you
          may not allow persons under the age of 18 to receive Services from
          Astrologer Partners, unless supervised by you. You may not assign or
          otherwise transfer your Account to any other person or entity. You
          agree to comply with all applicable laws when using the Services, and
          you may only use the Services for lawful purposes. You will not, in
          your use of Services, cause nuisance, annoyance, or inconvenience,
          whether to the Astrologer Partner or any other party.
        </Box3Text>
        <Box3Text>
          14. By creating an Account and providing your mobile phone number, you
          agree that you may be sent text (SMS) messages as part of your use of
          the Services. You may opt-out of receiving text (SMS) messages from
          the Company at any time by accessing the in-app customer support or by
          sending an email to [email address]. You acknowledge that opting out
          of receiving text (SMS) messages may impact your use of the Services.
        </Box3Text>
        <Box3Text>
          15. The Company may, in its sole discretion, create promotional codes
          that may be redeemed for Account credit, or other features or benefits
          related to the Services, subject to any additional terms that the
          Company establishes on a per promotional code basis (“Promo Codes”).
          You agree that Promo Codes: (i) must be used for the intended audience
          and purpose, and in a lawful manner; (ii) may not be duplicated, sold
          or transferred in any manner or made available to the general public
          unless expressly permitted by the Company; (iii) may be disabled by
          the Company for any reason without liability to the Company; (iv) may
          only be used pursuant to the specific terms that the Company
          establishes for such Promo Codes; (v) are not valid for cash; and (vi)
          may expire prior to your use. The Company reserves the right to
          withhold or deduct credits or other features or benefits obtained
          through the use of Promo Codes by you or any other user in the event
          that the Company determines or believes that the use or redemption of
          the Promo Code was in error, fraudulent, illegal, or in violation of
          the applicable Promo Code terms or these Terms.
        </Box3Text>
        <Box3Text>
          16. You are responsible for obtaining the data network access
          necessary to use the Services. Your mobile network’s data and
          messaging rates and fees may apply if you access or use the Services
          from a wireless-enabled device, and you shall be responsible for such
          rates and fees. You are responsible for acquiring and updating
          compatible hardware or devices necessary to access and use the
          Services and Applications and any updates thereto. The Company does
          not guarantee that the Services, or any portion thereof will function
          on any particular hardware or devices. In addition, the Services may
          be subject to malfunctions and delays inherent in the use of internet
          and electronic communications.
        </Box3Text>
      </Box3>
      <PaymentHeading variants={text1Variants}> III. PAYMENT</PaymentHeading>
      <PaymentSectionWrapper variants={text2Variants}>
        <PaymentText>
          17. For availing certain parts of Services, you may be required to
          purchase in-app credits (“Credits”) by making payments in Indian
          National Rupees (INR). The Credits will be stored in your Account and
          a summary of the transactions carried out using the same will be
          available to you. In the event the Account is compromised and any
          Credits are utilized, spent, or lost, you agree that the Company shall
          not be liable to reimburse the same. You further agree that Credits
          cannot be transferred to another Account.
        </PaymentText>
        <PaymentText>
          18. Credits may be spent by gifting various in-app items to Astrologer
          Partners. Credits may also be spent for voice and/or video-based
          consultation with the Astrologer Partners, for which Credits shall be
          deducted for each minute of consultation, or part thereof, as per the
          rates informed to you by the Company. The rates chargeable for such
          consultation shall be determined by the Company, and may vary for
          different Astrologer Partners. Such rates may also be revised by the
          Company from time to time, without any specific notification to that
          effect. You agree that such rates are only intended to compensate the
          Astrologer Partners for the time and effort expended by them, and not
          towards any product thereof, such as prediction, advice or remedy that
          may be generated by expending such time and effort.
        </PaymentText>
        <PaymentText>
          19. After you spend the Credits, the Company will facilitate payment
          to the concerned Astrologer Partner of a sum equivalent to the value
          of spent Credits. All payments made by you shall be inclusive of all
          applicable taxes. This payment structure is intended to fully
          compensate the Astrologer Partner for the services received by you,
          and you shall not be required to pay any other amounts to the
          Astrologer Partner.
        </PaymentText>
        <PaymentText>
          20. After you have received services from an Astrologer Partner, you
          will have the opportunity to provide ratings and reviews. The Company
          reserves the right to moderate and edit any rating and/or review that
          may be submitted by you.
        </PaymentText>
        <PaymentText>
          21. The Company may, from time to time, provide certain users with
          promotional offers, subscriptions, and discounts that may result in
          different amounts being charged for the same or similar services
          obtained through the use of the Services, and you agree that such
          promotional offers, subscriptions, and discounts, unless also made
          available to you, shall have no bearing on your use of the Services,
          or the payments to be made by you.
        </PaymentText>
        <PaymentText>
          22. Credits, once purchased, cannot be refunded. All transactions made
          using Credits, unless stated otherwise, are final and irreversible. In
          the event you have purchased in-app currency and are dissatisfied with
          the Services, you may contact the Company’s quality assurance
          representatives at <a href="mailto:your@email.com">your@email.com</a>{" "}
          and lodge a complaint. For such complaints to be valid, the same has
          to be received within seven (7) days of the occurrence of the event
          giving rise to such dissatisfaction. Any requests for a refund shall
          be processed by the Company as per its refund policy, which is
          appended to these Terms by way of a schedule and is available at{" "}
          <PaymentLink href="https://gurucool.life/refund-policy">
            refund policy
          </PaymentLink>
          . The said refund policy shall form a part of these Terms.
        </PaymentText>
        <PaymentText>
          23. In case you have any other query or require support regarding the
          Services, you may contact the Company’s support representatives using
          the in-app customer support chat or write at{" "}
          <a href="mailto:your@email.com">your@email.com</a>.
        </PaymentText>
      </PaymentSectionWrapper>
      <WarrentyHeading variants={text1Variants}>
        {" "}
        IV. WARRANTIES AND DISCLAIMERS
      </WarrentyHeading>
      <DisclaimerWrapper variants={text2Variants}>
        <DisclaimerText>
          24. The Services are provided on an “AS IS” and “AS AVAILABLE” basis.
          The Company disclaims all representations and warranties, express,
          implied, or statutory, not expressly set out in these Terms, including
          the implied warranties of merchantability, fitness for a particular
          purpose, and non-infringement. In addition, the Company or the
          Astrologer Partners make no representation, warranty, or guarantee
          regarding the accuracy, reliability, quality, relevance, or use of the
          Services, or that the Services will be uninterrupted or error-free.
          The Company does not guarantee the quality, suitability, or ability of
          Astrologer Partners. You agree that the entire risk arising out of
          your use of the Services remains solely with you, to the maximum
          extent permitted under applicable law.
        </DisclaimerText>
        <DisclaimerText>
          25. The Company shall not be liable for indirect, incidental, special,
          exemplary, punitive, or consequential damages, including lost profits,
          lost data, personal/mental injury, or property damage related to, in
          connection with, or otherwise resulting from any use of the Services,
          even if the Company has been advised of the possibility of such
          damages.
        </DisclaimerText>
        <DisclaimerText>
          26. Neither the Company nor Astrologer Partner shall be liable for any
          damages, liability, or losses arising out of: (i) inaccuracy of any
          prediction made by an Astrologer Partner as a part of the provision of
          Services; (ii) acts of omission or commission by you or a third party
          relating to consultation provided by an Astrologer Partner as a part
          of the Services.
        </DisclaimerText>
        <DisclaimerText>
          27. The Company shall not be liable for any damages, liability, or
          losses arising out of (i) your use of or reliance on the Services or
          your inability to access or use the Services; or (ii) any independent
          transaction or relationship between you and any Astrologer Partner,
          even if the Company has been advised of the possibility of such
          damages.
        </DisclaimerText>
        <DisclaimerText>
          28. In no event shall the Company’s total liability to you exceed a
          sum equivalent to the actual value of the in-app currency expended by
          you in the 30-days prior to the occurrence of the incident giving rise
          to the liability.
        </DisclaimerText>
        <DisclaimerText>
          29. While the Services may be used by you to obtain astrological
          consultation, you agree that the Company does not provide any
          astrological consultation and has no responsibility or liability to
          you related to any astrological consultation, other than as expressly
          set forth in these Terms.
        </DisclaimerText>
      </DisclaimerWrapper>
      <InfoHeading variants={text1Variants}>
        {" "}
        V. WARRANTIES AND DISCLAIMERS
      </InfoHeading>
      <PrivacyPolicyWrapper variants={text2Variants}>
        <PrivacyPolicyText>
          30. During the provision of Services, including while accessing
          Gurucool Mobile Application, the Company may collect, process, and
          store your data, including your personal information. The Company’s
          policy regarding its practices, type of data or information collected,
          purpose of collection and usage, disclosure, and security practices
          and procedures adopted by it may be accessed at{" "}
          <span
            onClick={() => {
              navigate("/privacy-policy");
            }}
            style={{
              textDecoration: "none",
              color: "#0d6efd",
              cursor: "pointer",
            }}
          >
            privacy policy
          </span>
          . You may not access or avail the Services in the event you do not
          agree with the said policy.
        </PrivacyPolicyText>
        <PrivacyPolicyText>
          31. Without prejudice to consent granted under the said policy, you
          hereby expressly consent to the Company sharing your data or personal
          information with statutory authorities and third parties, in the event
          of an accident or complaint involving you or an Astrologer Partner.
        </PrivacyPolicyText>
      </PrivacyPolicyWrapper>
      <WavierHeading variants={text1Variants}> VI. WAIVER</WavierHeading>
      <ConsentWrapper variants={text2Variants}>
        <ConsentText>
          32. You hereby authorize and give consent to the Company to send you,
          either through itself or through any third party service provider,
          from time to time various information / alerts / SMS / other messages
          or calls or commercial communication, and other services on the
          telephone numbers provided by you, whether these numbers are
          registered with National Do Not Call Registry / listed in National
          Customer Preference Register or not. I also confirm that by sending of
          any such messages / calls, I will not hold the Company or its third
          party service providers liable / institute complaint under the Telecom
          Commercial Communications Customer Preference (TRAI) Regulations, 2010
          or such applicable regulations including any amendment thereof, as may
          be applicable from time to time. In case you wish to stop this
          service, you may contact the Company by accessing the in-app customer
          support, or by writing an email at , until which time this waiver
          shall continue to remain in effect.
        </ConsentText>
      </ConsentWrapper>
      <INDEMNITYHeading variants={text1Variants}>
        {" "}
        VII. INDEMNITY
      </INDEMNITYHeading>
      <IndemnifyWrapper variants={text2Variants}>
        <IndemnifyText>
          33. You agree to indemnify and hold the Company and its affiliates,
          officers, directors, employees and agents harmless from any and all
          claims, demands, losses, liabilities, and expenses (including legal)
          arising out of or in connection with (i) your use of the Services;
          (ii) your breach or violation of any of these Terms; or (iii) your
          violation of the rights of any third party, including Astrologer
          Partners.
        </IndemnifyText>
      </IndemnifyWrapper>
      <LegalHeading variants={text1Variants}>
        {" "}
        VIII. JURISDICTION AND DISPUTE RESOLUTION
      </LegalHeading>
      <LegalWrapper variants={text2Variants}>
        <LegalText>
          34. Except as otherwise set forth in these terms, these Terms shall be
          exclusively governed by and construed in accordance with the laws of
          India. Any dispute, conflict, claim or controversy arising out of or
          broadly in connection with or relating to the Services or these Terms,
          including those relating to its validity, its construction or its
          enforceability (each a “Dispute”) shall be settled by arbitration. To
          invoke this, the affected party shall serve on the other party a
          notice of dispute, containing in brief the facts and circumstances
          giving rise to the dispute, together with documents in support
          thereof. Within sixty (60) days of receipt of such notice, and the
          Dispute not having been settled, the Company and you shall each
          appoint an arbitrator, and the two arbitrators shall together appoint
          a presiding arbitrator. The arbitration shall be held at New Delhi,
          India and courts at New Delhi, India alone shall have jurisdiction.
        </LegalText>
        <LegalText>
          35. The information presented is for entertainment purposes only.
        </LegalText>
      </LegalWrapper>
    </Container>
  );
};

export default TermAndCondtion;

const Container = styled(motion.div)`
  padding: 10px 216px;
  /* position: relative;
  z-index: 1;
  background: none; */
`;

// styled component
const HelmetWrapper = styled(motion.div)`
  title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  meta[name="description"] {
    font-size: 16px;
    /* color: #333; */
  }
`;

const MainHeading = styled(motion.h1)`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 36px;
    margin-bottom: 35px;
    margin-top: 35px;
  }
`;

const SubHeading = styled(motion.h2)`
  font-size: 24px;
  font-weight: 500;
  margin: 8px 0;

  @media (min-width: 768px) {
    font-size: 35px;
  }
`;

const StyledBox = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const Text = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;
const GeneraHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;

const Box2 = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const Box2Text = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;
const StyledHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;
const Box3 = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const Box3Text = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;
const PaymentHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;

const PaymentSectionWrapper = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const PaymentText = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;

const PaymentLink = styled.a`
  text-decoration: none;
  color: #0d6efd;

  &:hover {
    color: black;
  }
`;
const WarrentyHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;
const DisclaimerWrapper = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const DisclaimerText = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;
const InfoHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;
const PrivacyPolicyWrapper = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const PrivacyPolicyText = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;

const WavierHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;
const ConsentWrapper = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const ConsentText = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;

const INDEMNITYHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;

const IndemnifyWrapper = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const IndemnifyText = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
`;
const LegalHeading = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  margin: 16px 0 8px 0;

  @media (min-width: 768px) {
    font-size: 28px;
    margin: 20px 0 8px 0;
  }
`;
const LegalWrapper = styled(motion.div)`
  margin-bottom: 14px;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

const LegalText = styled(motion.p)`
  font-size: 16px;
  text-align: justify;
  /* color: #333; */
  /* font-family: inter; */
  margin-bottom: 2rem;
`;
