import React from "react";
import HomePage from "./HomePage";
import Testimonials from "./Testimonials";
import MileStones from "./MileStones";
import MeaningPage from "./MeaningPage";
import Experience from "./Experience";
import Footer from "./Footer";
import StarsCanvas from "../components/star";

const Main = () => {
  return (
    <div style={{ position: "relative", zIndex: "0" }}>
      <HomePage />
      <Testimonials />
      <Experience />
      <MeaningPage />
      <MileStones />
      <Footer />
      <StarsCanvas />
    </div>
  );
};

export default Main;
