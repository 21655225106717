import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import styled from "styled-components";

const Experiencecard1 = ({ mainControls, img, title, subTitle, desc }) => {
  return (
    <>
      <Tilt>
        <ExperienceCard
          img={img}
          variants={{
            hidden: { opacity: 0, y: +200 },
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          animate={mainControls}
        >
          <div style={{ background: "none" }}>
            <CardTitle>{title}</CardTitle>
            <CardSubTitle>{subTitle}</CardSubTitle>
          </div>
          <CardContent>{desc}</CardContent>
        </ExperienceCard>
      </Tilt>
    </>
  );
};

export default Experiencecard1;

const ExperienceCard = styled(motion.div)`
  background-size: cover;
  background-image: url(${({ img }) => img});
  background-position: center;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 2.6vh 1vw;
  display: flex;
  gap: 18vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18.32vw;
  border-radius: 1vw;
`;
const CardContent = styled.div`
  background: none;
  text-align: center;
  line-height: 150%;
  width: 16vw;
  font-size: 1vw;
  font-weight: 500;
`;
const CardTitle = styled.p`
  font-size: 1vw;
  margin-bottom: 1.5vh;
  background: none;
  font-weight: 500;
  text-align: center;
`;
const CardSubTitle = styled.h4`
  font-size: 1.5vw;
  background: none;
  text-align: center;
  font-weight: 500;
`;
