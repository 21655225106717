import svg1 from "./mokups/expericnceSVG/gita.svg";
import svg2 from "./mokups/expericnceSVG/kabir.svg";

export let experienceData = [
  {
    img: svg1,
    title: '“Gita Harmony”',
    subTitle: "Bhagavad Gita",
    desc: "Explore the timeless wisdom of Bhagavad Gita with Slokha. Elevate your life through its profound teachings. Click 'Learn More' to delve into the spiritual journey with Gita.",
  },
  {
    img: svg2,
    title: `"Kabir's Echo"`,
    subTitle: "Kabir Ke Dohe",
    desc: "Immerse yourself in the echoing wisdom of Kabir Ke Dohe with Slokha. Enrich your life through the profound verses. Click 'Learn More' to embark on a spiritual journey with Kabir.",
  },
];
