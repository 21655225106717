import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useInView, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";
// import StarsCanvas from "../components/star";

const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  const Homeref = useRef(null); // useRef for animation trigger on scroll
  const isInView = useInView(Homeref, { once: false }); // function of  framer motion when we scroll our page or only animation run when we view the page
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible"); //mainControl also function of framer motion
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const text1Variants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const text2Variants = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1, delayChildren: 0.5, staggerChildren: 0.2 },
    },
  };

  return (
    // <div style={{ position: "relative", zIndex: "1" }}>
    <Container
      ref={Homeref}
      variants={containerVariants}
      initial="hidden"
      animate={mainControls}
    >
      <HelmetWrapper>
        <title>Slokha||Privacy Policy</title>
        <meta
          name="description"
          content="GuruCool prioritizes your privacy. Read our comprehensive privacy policy to understand how we collect, use, and protect your personal information. Your data security is our top concern."
        />
      </HelmetWrapper>
      <BoxWrapper
        variants={containerVariants}
        initial="hidden"
        animate={mainControls}
      >
        <FlexContainer>
          <FlexContainer>
            <Heading variants={text1Variants}>Privacy Policy</Heading>
          </FlexContainer>
        </FlexContainer>
        <Divider />
        <TextWrapper variants={text2Variants}>
          At Slokha, we understand the importance of your privacy and are
          committed to protecting it. This privacy policy explains how we
          collect, use, and disclose your personal information when you use our
          website, mobile application, or any other service we offer
          (collectively, the "Services").
        </TextWrapper>
        <TextWrapper variants={text2Variants}>
          This Privacy Policy is published in accordance with Rule 3(1) of the
          Information Technology (Intermediaries Guidelines) Rules, 2011 and
          Information Technology (Reasonable Security Practices and Procedures
          and Sensitive Personal Data or Information) Rules, 2011 which requires
          publishing of the Privacy policy for collection, use, storage, and
          transfer of sensitive personal data or information.
        </TextWrapper>
        <HeadingWrapper variants={text1Variants}>
          {" "}
          I. Collection of Information
        </HeadingWrapper>
        <TextWrapper variants={text2Variants}>
          We may collect personal information, such as your name, email address,
          phone number, and payment information, when you use our Services. We
          may also collect non-personal information, such as your device
          information, browser type, and IP address.
        </TextWrapper>
        <HeadingWrapper variants={text1Variants}>
          II. Use of Information
        </HeadingWrapper>
        <TextWrapper variants={text2Variants}>
          We use your personal information to provide you with the Services,
          process your payments, communicate with you, and improve our Services.
          We may also use your information for research and analysis purposes.
        </TextWrapper>
        <HeadingWrapper variants={text1Variants}>
          III. Disclosure of Information
        </HeadingWrapper>
        <TextWrapper variants={text2Variants}>
          We do not sell or rent your personal information to third parties. We
          may disclose your personal information to our trusted service
          providers who assist us in providing the Services. We may also
          disclose your information to comply with legal obligations, protect
          our rights and property, and prevent fraud or other illegal
          activities.
        </TextWrapper>
        <HeadingWrapper variants={text1Variants}>
          IV. Security of Information
        </HeadingWrapper>
        <TextWrapper variants={text2Variants}>
          We take appropriate measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no method of transmission over the internet or electronic storage is
          completely secure. Therefore, we cannot guarantee absolute security.
        </TextWrapper>
        <HeadingWrapper variants={text1Variants}>
          V. Changes to Privacy Policy
        </HeadingWrapper>
        <TextWrapper variants={text2Variants}>
          We may update this privacy policy from time to time. We will notify
          you of any changes by posting the new privacy policy on our website.
          You are advised to review this policy periodically for any changes.
        </TextWrapper>
        <HeadingWrapper variants={text1Variants}>V. Contact Us</HeadingWrapper>
        <TextWrapper variants={text2Variants}>
          If you have any questions or concerns about our privacy policy, please
          contact us at hi@gurucool.life.
        </TextWrapper>
      </BoxWrapper>
    </Container>
    // <StarsCanvas width="100vw" height="full" size="0.0009" />
    // </div>
  );
};

export default PrivacyPolicy;

const Container = styled(motion.div)`
  padding: 10px 216px;
  /* position: relative;
  z-index: 1;
  background: none; */
`;
const HelmetWrapper = styled.div`
  title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  meta[name="description"] {
    font-size: 16px;
    color: #333;
  }
`;

const Heading = styled(motion.h2)`
  font-size: 24px;
  font-weight: 700;
  background: none;
  text-align: center;
  /* margin-bottom: 40px; */
  /* display: flex; */
  @media (min-width: 768px) {
    font-size: 36px;

    /* margin-bottom: 70px; */
  }
`;
const BoxWrapper = styled(motion.div)`
  margin: 40px 0 55px;
  background: none;

  @media (min-width: 768px) {
    margin: 70px 0 72px;
  }
`;

const FlexContainer = styled(motion.div)`
  display: flex;
  background: none;

  justify-content: center;
`;

const LogoImage = styled.img`
  width: 40px;
  margin-right: 15px;
  background: none;

  @media (min-width: 768px) {
    width: 54px;

    margin-bottom: 70px;
  }
`;

const HeadingWrapper = styled(motion.h2)`
  font-size: 20px;
  font-weight: 500;
  background: none;

  text-align: center;
  /* margin-bottom: 40px; */
  display: flex;
  @media (min-width: 768px) {
    font-size: 28px;

    /* margin-bottom: 70px; */
  }
`;

const Divider = styled(motion.hr)`
  border: 1px solid #ccc;
  width: 100%;
  margin: 2vh auto;
  background: none;

  @media (min-width: 768px) {
    width: 6xl;
  }
`;

const TextWrapper = styled(motion.p)`
  margin-bottom: 14px;
  background: none;

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`;
