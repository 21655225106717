import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Star } from "../assets/assets";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { motion, useAnimation, useInView } from "framer-motion";
import img1 from "../assets/mokups/testimonials/testi1.svg";
import img2 from "../assets/mokups/testimonials/testi2.svg";
import img3 from "../assets/mokups/testimonials/testi3.svg";
import img4 from "../assets/mokups/testimonials/testi4.svg";

const Testimonials = () => {
  const refs = useRef(null);
  const isInView = useInView(refs, { once: false });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  return (
    <>
      <MainDiv>
        <TestimonialsHeading
          variants={{
            hidden: { opacity: 0, x: -200 },
            visible: { opacity: 1, x: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          animate={mainControls}
         ref={refs}>
          Testimonials
        </TestimonialsHeading>
        <TestimonialsP
          variants={{
            hidden: { opacity: 0, x: 200 },
            visible: { opacity: 1, x: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          animate={mainControls}
        >
          Slokha Stories: Unveiling the Positive Shifts in Everyday Lives
        </TestimonialsP>
        {/* <div style={{ background: "none" }} ref={refs}></div> */}
        <OwlCarousel
          className="owl-theme"
          items="1"
          // autoplay
          // autoplayTimeout={3000}
          // autoplayHoverPause
          dots
          // loop
          center
        >
          <Owldiv
            className="item"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "70px",
            }}
          >
            <CardDiv
              variants={{
                hidden: { opacity: 0, x: 300 },
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              <ImgSvg
                src={img1}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              />
              <StarDiv
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
              </StarDiv>
              <TestimonialsPara
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                "Discovering the Slokha app has been a transformative
                experience. In the midst of a busy life, Slokha serves as my
                anchor, providing powerful insights and wisdom that resonate
                deeply. What sets Slokha apart is not just the richness of its
                content but the tangible impact it has on individuals. I'm
                grateful for the positive change it has brought to my life,
                making it a must-try for those on a journey of self-discovery
                and mindfulness."
              </TestimonialsPara>
              <TestimonialsName
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                Priya Jain
              </TestimonialsName>
              <TestimonialPlace
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                India Delhi
              </TestimonialPlace>
            </CardDiv>
          </Owldiv>
          <Owldiv
            className="item"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "70px",
            }}
          >
            <CardDiv
              variants={{
                hidden: { opacity: 0, x: 300 },
                visible: { opacity: 1, x: 0, transition: { duration: 3 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              <ImgSvg
                src={img2}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1, transition: { duration: 3 } },
                }}
                initial="hidden"
                animate={mainControls}
              />
              <StarDiv
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
              </StarDiv>
              <TestimonialsPara
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                "Slokha is more than just an app; it's a sanctuary of wisdom and
                inspiration. The profound lessons from Bhagavad Gita and Kabir
                ke dohe have had a transformative effect on my daily life.
                Slokha's unique ability to curate teachings, commentary, and
                meaningful quotes from experienced astrologers and pandits sets
                it apart. Connecting with a community that shares a similar
                journey has added a valuable dimension to my experience. If
                you're seeking not just an app but a companion on your path to
                self-discovery, Slokha is the answer."
              </TestimonialsPara>
              <TestimonialsName
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.5 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                Ashish Mehra
              </TestimonialsName>
              <TestimonialPlace
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.8 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                India Jaipur
              </TestimonialPlace>
            </CardDiv>
          </Owldiv>
          <Owldiv
            className="item"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "70px",
            }}
          >
            <CardDiv
              variants={{
                hidden: { opacity: 0, x: 300 },
                visible: { opacity: 1, x: 0, transition: { duration: 3 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              <ImgSvg
                src={img3}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1, transition: { duration: 3 } },
                }}
                initial="hidden"
                animate={mainControls}
              />
              <StarDiv
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
              </StarDiv>
              <TestimonialsPara
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                Slokha has become my daily sanctuary for peace and inspiration.
                The curated content, combined with the insights from
                astrologers, has added a unique depth to my spiritual journey.
                Each verse feels like a guiding light, and being part of a
                community that shares this profound experience is truly
                uplifting. Grateful for the wisdom and connection Slokha has
                brought into my life."
              </TestimonialsPara>
              <TestimonialsName
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.5 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                Rohit Jangir
              </TestimonialsName>
              <TestimonialPlace
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.8 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                India Mumbai
              </TestimonialPlace>
            </CardDiv>
          </Owldiv>
          <Owldiv
            className="item"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "70px",
            }}
          >
            <CardDiv
              variants={{
                hidden: { opacity: 0, x: 300 },
                visible: { opacity: 1, x: 0, transition: { duration: 3 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              <ImgSvg
                src={img4}
                variants={{
                  hidden: { opacity: 0 },
                  visible: { opacity: 1, transition: { duration: 3 } },
                }}
                initial="hidden"
                animate={mainControls}
              />
              <StarDiv
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
                <Star width="2.1vw" height="2.1vw" />
              </StarDiv>
              <TestimonialsPara
                variants={{
                  hidden: { opacity: 0, x: -300 },
                  visible: { opacity: 1, x: 0, transition: { duration: 2 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                "Slokha has become an integral part of my daily routine. Its
                profound teachings from Bhagavad Gita and Kabir ke dohe bring a
                sense of calm and purpose to my otherwise hectic life. The app's
                ability to seamlessly integrate ancient wisdom into the modern
                world is truly commendable. I highly recommend Slokha to anyone
                seeking a positive shift, mindfulness, and inspiration in their
                daily lives."
              </TestimonialsPara>
              <TestimonialsName
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.5 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                Sakshi Jain
              </TestimonialsName>
              <TestimonialPlace
                variants={{
                  hidden: { opacity: 0, x: -100 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.8 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                India Mumbai
              </TestimonialPlace>
            </CardDiv>
          </Owldiv>
        </OwlCarousel>
      </MainDiv>
    </>
  );
};

export default Testimonials;

const Owldiv = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-top: 6.6vh;
`;

const MainDiv = styled.div`
  height: 100vh;
  padding: 5.6vh 11.08vw 9.1vh 11.14vw;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TestimonialsHeading = styled(motion.h1)`
  font-size: 3.3vw;
  background: none;
  position: relative;
  z-index: 1;
  font-weight: 700;
  letter-spacing: 0.25rem;
  text-align: center;
  margin-bottom: 3vh;
`;

const TestimonialsP = styled(motion.h3)`
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: 1.56vw;
  font-weight: 500;
  /* margin-bottom: 2vh; */
`;
export const ImgSvg = styled(motion.img)`
  position: absolute;
  background: none;
  width: 9.1vw !important;
  padding: 5px;
  top: -14%;
`;
const CardDiv = styled(motion.div)`
  position: relative;
  border-radius: 2.39vw;
  width: 56.4vw;
  /* height: 48vh; */
  /* margin-top: 20px; */
  border: 0.1vw solid ${(props) => props.theme.colors.secondary};
  padding: 9.9vh 3.7vw 4.8vh 2.08vw;
  /* margin-bottom: 1.5vh; */
`;

const StarDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
const TestimonialsPara = styled(motion.h4)`
  font-size: 1.24vw;
  text-align: left;
  /* width: 48.25vw; */
  font-weight: 500;
  margin-bottom: 1.6vh;
  margin-top: 1.6vh;
`;

const TestimonialsName = styled(motion.h1)`
  font-weight: 800;
  font-size: 2.1vw;
`;

const TestimonialPlace = styled(motion.p)`
  font-size: 1.5vw;
  font-weight: 400;
`;
