import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import playstore from "../assets/playstore.svg";
import appstore from "../assets/app.svg";
import logo from "../assets/webIcon.png";
import logo2 from "../assets/webIcon2.png";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const Fref = useRef(null);
  const isInView = useInView(Fref, { once: false });
  const mainControls = useAnimation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  return (
    <Container>
      <HrLine1></HrLine1>
      <MainDiv>
        <div>
          <QuoteDiv>
            <LogoDiv
              variants={{
                hidden: { opacity: 0, x: -200 },
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              <img width="50vw" src={logo} alt="shloka" />
              <img height="32vh" src={logo2} alt="shloka" />
            </LogoDiv>
            <FooterPara
              ref={Fref}
              variants={{
                hidden: { opacity: 0, x: 200 },
                visible: {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1 },
                },
              }}
              initial="hidden"
              animate={mainControls}
            >
              At Shloka, we're passionate about making ancient wisdom accessible
              to modern minds.
            </FooterPara>
          </QuoteDiv>
          <Address
            variants={{
              hidden: { opacity: 0, x: 150 },
              visible: { opacity: 1, x: 0, transition: { duration: 2 } },
            }}
            initial="hidden"
            animate={mainControls}
          >
            Nohata House,A-90 Viduyth Nagar, Jaipur, India 302021
          </Address>
        </div>
        <div>
          <FooterMenu
            variants={{
              hidden: { opacity: 0, y: 200 },
              visible: { opacity: 1, y: 0, transition: { duration: 1 } },
            }}
            initial="hidden"
            animate={mainControls}
          >
            {/* <HrTag></HrTag> */}
            <Menu>
              <TagContainer
                variants={{
                  hidden: { opacity: 0, x: 300 },
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1 },
                  },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Tag
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </Tag>
                <Tag>About Us</Tag>
                <Tag>Contact Us</Tag>
              </TagContainer>
            </Menu>
            {/* <HrLine></HrLine> */}
            <TandC>
              <Tag
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </Tag>
              <Tag
                onClick={() => {
                  navigate("/term-and-condition");
                }}
              >
                T & C
              </Tag>
            </TandC>
            <ImgContainer>
              <img src={playstore} style={{ width: "7.81vw" }} alt="" />
              <img src={appstore} style={{ width: "7.81vw" }} alt="" />
            </ImgContainer>
          </FooterMenu>
        </div>
      </MainDiv>

      <HrLine1></HrLine1>
      <CopyRight
        variants={{
          hidden: { opacity: 0, scale: 0 },
          visible: {
            opacity: 1,
            scale: 1,
            transition: { duration: 1 },
          },
        }}
        initial="hidden"
        animate={mainControls}
      >
        © 2023 Drall & Dholiya Private Limited
      </CopyRight>
    </Container>
  );
};

export default Footer;

const LogoDiv = styled(motion.div)`
  display: flex;
  align-items: flex-end;
`;
const Container = styled.div`
  position: relative;
  z-index: 1;
`;
const ImgContainer = styled.div`
  margin-left: 1.4vw;
  margin-top: 4vh;
  display: flex;
  gap: 1vw;
`;

const TandC = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2.2vh;
  margin-left: 1.4vw;
`;
const MainDiv = styled.div`
  background: none;
  display: flex;
  padding: 5vh 8.5vw 3.64vh 10.5vw;
  flex-direction: row;
  gap: 20.7vw;
  justify-content: space-between;
`;
// const LogoHeading = styled(motion.h1)`
//   font-size: 3.33vw;
//   font-weight: 700;
//   margin-bottom: 3vh;
// `;
const QuoteDiv = styled.div`
  margin-bottom: 6.54vh;
  background: none;
  display: flex;
  align-items: left;
  flex-direction: column;
  /* justify-content: space-between; */
`;
const FooterPara = styled(motion.p)`
  font-size: 1vw;
  width: 22vw;
  line-height: 200%;
  text-align: left;
  font-weight: 500;
`;
const FooterMenu = styled(motion.div)`
  align-items: flex-start;
`;
const Menu = styled.div`
  padding-left: 1.3vw;
  border-left: 1px solid ${(props) => props.theme.colors.secondary};
`;
const TagContainer = styled(motion.div)`
  display: flex;
  width: fit-content;
  background: none;
  flex-direction: column;
  gap: 2.9vh;
`;

const Tag = styled(motion.p)`
  font-size: 1vw;
  cursor: pointer;
  background: none;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  }
`;
// const HrLine = styled.hr`
//   border: 0.2px solid ${(props) => props.theme.colors.secondary};
//   width: 3.64vw;
//   margin-left: 2vw;
//   margin-top: 1.5vh;
// `;
const Address = styled(motion.p)`
  font-size: 1vw;
  width: 17vw;
  font-weight: 500;
`;
const HrLine1 = styled(motion.hr)`
  background: none;
  border: 1px solid ${(props) => props.theme.colors.secondary};
`;

const CopyRight = styled(motion.p)`
  font-size: 1vw;
  background: none;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  text-align: center;
  font-weight: 500;
`;
