import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import ScrollTrigger from "react-scroll-trigger";
import { motion, useAnimation, useInView } from "framer-motion";
import CountUp from "react-countup";

const MileStones = () => {
  const [counterOn, setCounterOn] = useState(false);
  const Mref = useRef(null);
  const isInView = useInView(Mref, { once: false });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  return (
    <Container>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
        style={{
          scrollSnapAlign: "start",
          background: "none",
          position: "relative",
          zIndex: "3",
        }}
      >
        <div
          ref={Mref}
          style={{ scrollSnapAlign: "start", background: "none", zIndex: "3" }}
        >
          <MilestonesContainer>
            <MilestonesHeading
              variants={{
                hidden: { opacity: 0, x: -200 },
                visible: { opacity: 1, x: 0, transition: { duration: 2 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              Empowering Milestones
            </MilestonesHeading>
            <MilestonesPara
              variants={{
                hidden: { opacity: 0, x: 200 },
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              Embark on a transformative journey with Shloka, where each verse
              shared, every user embraced, and every astrologer's insight
              contributes to the empowering milestones of our vibrant community.
              Together, we shape a future driven by shared wisdom and the
              pursuit of enlightenment.
            </MilestonesPara>
            <CountMilestone>
              <SlokasBox
                variants={{
                  hidden: { opacity: 0, x: -200 },
                  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Count>
                  {counterOn && (
                    <CountUp
                      style={{ color: "#4CB7EF" }}
                      end={5000}
                      duration={3}
                    />
                  )}
                </Count>
                <CountHead>shloks</CountHead>
              </SlokasBox>

              <SlokasBox
                variants={{
                  hidden: { opacity: 0, x: 200 },
                  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Count>
                  {counterOn && (
                    <CountUp
                      style={{ color: "#4CB7EF" }}
                      end={5}
                      duration={4}
                    />
                  )}
                  K
                </Count>
                <CountHead>Uses</CountHead>
              </SlokasBox>
              <SlokasBox
                variants={{
                  hidden: { opacity: 0, y: 200 },
                  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                }}
                initial="hidden"
                animate={mainControls}
              >
                <Count>
                  {counterOn && (
                    <CountUp
                      style={{ color: "#4CB7EF" }}
                      end={200}
                      duration={3}
                    />
                  )}
                  +
                </Count>
                <CountHead>Astrologers</CountHead>
              </SlokasBox>
            </CountMilestone>
            <AppButton
              variants={{
                hidden: { opacity: 0, scale: 0 },
                visible: { opacity: 1, scale: 1, transition: { duration: 2 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              Download App Now
            </AppButton>
          </MilestonesContainer>
        </div>
      </ScrollTrigger>
    </Container>
  );
};

export default MileStones;

const Container = styled.div`
  position: relative;
  z-index: 1;
  background: none;
`;

const MilestonesContainer = styled.div`
  background: none;
  padding: 5.1vh 14.97vw 17.57vh 11.19vw;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const MilestonesHeading = styled(motion.h1)`
  font-size: 3.33vw;
  background: none;
  text-align: center;
  font-weight: 700;
  margin-bottom: 3.64vh;
`;
const MilestonesPara = styled(motion.h4)`
  width: 52vw;
  background: none;
  text-align: center;
  font-size: 1.24vw;
  margin-bottom: 2.18vh;
  font-weight: 500;
`;
const CountMilestone = styled.div`
  display: flex;
  background: none;
  justify-content: space-between;
  align-items: center;
  width: 52vw;
`;
const SlokasBox = styled(motion.div)`
  display: flex;
  background: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Count = styled.h1`
  background: none;
  color: ${(props) => props.theme.colors.secondary} !important;
  font-size: 3.33vw;
  font-weight: 700;
`;
const CountHead = styled.h3`
  font-size: 1.56vw;
  font-weight: 500;
  background: none;
`;
const AppButton = styled(motion.button)`
  border: none;
  font-size: 1.56vw;
  font-weight: 500;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 2.18vh 2.08vw;
  border-radius: 0.6vw;
  margin-top: 4.52vh;
`;
