import React, { useEffect, useRef } from "react";
import { Tilt } from "react-tilt";
import styled, { keyframes, css } from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import { experienceData } from "../assets/ExperienceMoke";
import Experiencecard1 from "../components/Reusable/ExperienceCard";

const Experience = () => {
  const Exref = useRef(null);
  const isInView = useInView(Exref, { once: false });
  const mainControls = useAnimation();

  const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateX(-1vw);
  }
  50% {
    transform: translateX(1vw);
  }
`;

  const BounceSvg = styled.svg`
    ${(props) =>
      props.bouncing &&
      css`
        animation: ${bounceAnimation} 2s infinite;
      `}
  `;
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);
  return (
    <Container>
      <MainContainer style={{ scrollSnapAlign: "start" }}>
        <ExperienceHeading
          variants={{
            hidden: { opacity: 0, x: -200 },
            visible: { opacity: 1, x: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          animate={mainControls}
        >
          The Shloka Experience
        </ExperienceHeading>
        <ExperienceDetails
          variants={{
            hidden: { opacity: 0, x: 200 },
            visible: { opacity: 1, x: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          animate={mainControls}
        >
          Timeless Wisdom for Modern Living
        </ExperienceDetails>
        <ExperiencePara
          variants={{
            hidden: { opacity: 0, x: -200 },
            visible: { opacity: 1, x: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          ref={Exref}
          animate={mainControls}
        >
          Embark on a journey of self-discovery with Shloka's curated wisdom.
          Elevate your life through lessons that transcend time. Click{" "}
          <LearnLink>'Learn More'</LearnLink> to explore the essence of Shloka.
        </ExperiencePara>
        <ExperienceContainer>
          {experienceData?.map((data, index) => (
            <Experiencecard1
              key={index}
              mainControls={mainControls}
              img={data?.img}
              title={data?.title}
              subTitle={data?.subTitle}
              desc={data?.desc}
            />
          ))}
          <Tilt>
            <ExperienceCard1
              variants={{
                hidden: { opacity: 0, x: 200 },
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
              }}
              initial="hidden"
              animate={mainControls}
            >
              <div
                classname="div1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10.21vh",
                  height: "100%",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <BounceSvg
                  bouncing
                  className="BounceButton"
                  width="6vw"
                  // height="81"
                  viewBox="0 0 98 81"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.000173706 40.5C0.000174072 32.1251 6.81554 25.3097 15.1905 25.3097L39.3126 25.3097C34.1277 19.5222 34.3555 9.99284 39.8949 4.44332C42.7448 1.59806 46.6074 -2.22156e-06 50.6345 -2.04553e-06C54.6616 -1.8695e-06 58.5241 1.59806 61.374 4.44332L97.4307 40.5L61.374 76.5567C58.5241 79.4019 54.6616 81 50.6345 81C46.6074 81 42.7448 79.4019 39.8949 76.5567C34.3505 71.0173 34.1226 61.4727 39.3126 55.6903L15.1905 55.6903C6.81554 55.6903 0.00017334 48.8749 0.000173706 40.5ZM63.7285 35.4366L15.1905 35.4366C13.8867 35.4949 12.6557 36.0538 11.7538 36.997C10.8518 37.9402 10.3485 39.195 10.3485 40.5C10.3485 41.805 10.8518 43.0598 11.7538 44.003C12.6557 44.9462 13.8867 45.5051 15.1905 45.5634L63.7285 45.5634L47.0546 62.2373C46.1329 63.2012 45.6185 64.4835 45.6185 65.8171C45.6185 67.1508 46.1329 68.4331 47.0546 69.397C47.5244 69.8676 48.0824 70.241 48.6967 70.4957C49.311 70.7505 49.9695 70.8816 50.6345 70.8816C51.2995 70.8816 51.9579 70.7505 52.5722 70.4957C53.1865 70.241 53.7445 69.8676 54.2143 69.397L83.1113 40.5L54.2143 11.603C53.7445 11.1324 53.1865 10.759 52.5722 10.5043C51.9579 10.2495 51.2995 10.1184 50.6345 10.1184C49.9695 10.1184 49.311 10.2495 48.6967 10.5043C48.0825 10.759 47.5244 11.1324 47.0546 11.603C46.1329 12.5669 45.6185 13.8492 45.6185 15.1829C45.6185 16.5165 46.1329 17.7988 47.0546 18.7627L63.7285 35.4366Z"
                    fill="#4CB7EF"
                  />
                </BounceSvg>
                <CardTitle1 classname="title">Explore More</CardTitle1>
              </div>
            </ExperienceCard1>
          </Tilt>
        </ExperienceContainer>
      </MainContainer>
      <div
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          background: "none",
          justifyContent: "center",
        }}
      >
        <PathHeading
          variants={{
            hidden: { opacity: 0, x: 200 },
            visible: { opacity: 1, x: 0, transition: { duration: 1 } },
          }}
          initial="hidden"
          animate={mainControls}
        >
          Path of Enlightenment
        </PathHeading>
      </div>
    </Container>
  );
};

export default Experience;

const Container = styled.div`
  position: relative;
  background: none;
  z-index: 1;
`;

const PathHeading = styled(motion.h1)`
  font-size: 3.3vw;
  text-align: center;
  font-weight: 700;
  margin-top: 5.1vh;
`;

const MainContainer = styled.div`
  scroll-snap-align: start;
  position: relative;
  z-index: 1;
  background: none;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 11vh 11vw 7.8vh 11vw;
`;
const ExperienceHeading = styled(motion.h1)`
  font-size: 3.3vw;
  background: none;
  font-weight: 700;
  text-align: center;
  background: none;
  letter-spacing: 0.4px;
  margin-bottom: 3.6vh;
`;
const ExperienceDetails = styled(motion.p)`
  font-size: 1.5vw;
  font-weight: 500;
  background: none;
  text-align: center;
  margin-bottom: 2.8vh;
`;
const ExperiencePara = styled(motion.p)`
  font-size: 0.95vw;
  background: none;
  text-align: center;
  font-weight: 500;
  width: 44vw;
  margin-bottom: 9.4vh;
  text-align: center;
`;
const LearnLink = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 0.95vw;
  font-weight: 500;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
`;

const ExperienceContainer = styled.div`
  display: flex;
  background: none;
  gap: 4.8vw;
`;
const ExperienceCard1 = styled(motion.div)`
  height: 100% !important;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 3.06vh 2.1vw;
  display: flex;
  gap: 10.21vh;
  cursor: pointer;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  width: 10vw !important;
  border-radius: 1.5vw !important;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary_dark};
    div {
      background-color: ${(props) => props.theme.colors.primary_dark};
    }
    svg {
      background-color: ${(props) => props.theme.colors.primary_dark};
    }
    h1 {
      background-color: ${(props) => props.theme.colors.primary_dark};
    }

    .BounceButton {
      animation-play-state: paused;
    }
  }
`;
const CardTitle1 = styled.h1`
  font-size: 1.5vw;
  font-weight: 500;
`;
