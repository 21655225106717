import svg1 from "./mokups/MeangingCardSvg/meaning.svg";
import svg2 from "./mokups/MeangingCardSvg/Commentary.svg";
import svg3 from "./mokups/MeangingCardSvg/Learning.svg";
import svg4 from "./mokups/MeangingCardSvg/Quote.svg";
import macsvg1 from "./mokups/MeangingCardSvg/macmeaning.svg";
import macsvg2 from "./mokups/MeangingCardSvg/maccommentary.svg";
import macsvg3 from "./mokups/MeangingCardSvg/maclearning.svg";
import macsvg4 from "./mokups/MeangingCardSvg/macquote.svg";
export let data = [
  {
    img: svg1,
    macImg: macsvg1,
    title: "Meaning",
    subTitle: "Unlock the hidden meanings within ancient texts",
    UnOrderList: [
      "Explore the cultural and historical context of sacred verses.",
      "Decode metaphors and symbolism for a richer understanding.",
      "Unravel the layers of wisdom embedded in each verse.",
    ],
    color: "#9299B6",
  },
  {
    img: svg2,
    macImg: macsvg2,
    title: "Commentry",
    subTitle: "Guided clarity for modern minds.",
    UnOrderList: [
      "Navigate through complex spiritual concepts made accessible.",
      "Gain insights into the relevance of ancient teachings in contemporary life.",
      "Access expert perspectives for a comprehensive understanding.",
    ],
    color: "#D4A87F",
  },
  {
    img: svg3,
    macImg: macsvg3,
    title: "Learning",
    subTitle: "Glean knowledge from seasoned masters.",
    UnOrderList: [
      "Handpicked teachings by accomplished astrologers and pandits.",
      "Acquire practical life lessons from experienced mentors.",
      "Gain a deeper appreciation for the art of learning and self-discovery.",
    ],
    color: "#9299B6",
  },
  {
    img: svg4,
    macImg: macsvg4,
    title: "Quote",
    subTitle: "Wisdom distilled in every phrase.",
    UnOrderList: [
      "Curated quotes capturing the essence of profound teachings.",
      "Reflect on concise and powerful messages that inspire.",
      "Connect with the transformative power of distilled wisdom.",
    ],
    color: "#B0C692",
  },
];
