
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Main from "./pages/Main";
import { Route, Routes } from "react-router-dom";
import TermAndCondtion from "./pages/TermAndCondtion";
import PrivacyPolicy from "./pages/PrivacyPolicy";

gsap.registerPlugin(ScrollTrigger);



const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/term-and-condition' element={<TermAndCondtion />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      </Routes>
    </>
  )
}

export default App
