export const theme = {
    colors: {
        primary_light: '#FDFDFD',
        secondary_light: '#F0F0F0',
        primary_dark: '#272C33',
        secondary_dark: "#121E2A",
        secondary: "#4CB7EF",
        acccent_color1: "#E07A5F",
        acccent_color2: "#D4A87F",
        acccent_color3: "#9299B6",
        acccent_color4: "#B0C692",
    },
    fonts: {
        family: "Noto Sans",
        weight: {
            regular: 400,
            media: 600,
            bold: 700,
        }
    },
    breakpoints: {
        mobile: '456px',
    }

}