import { useState, useRef, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial, Preload } from "@react-three/drei";
import * as random from "maath/random/dist/maath-random.esm";
import styled from "styled-components";

// Stars component responsible for rendering the stars
const Stars = (props) => {
  const ref = useRef();
  const [sphere] = useState(() =>
    random.inSphere(new Float32Array(50000), { radius: 1.2 })
  );

  // Use the useFrame hook to update rotation of the stars on each frame
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 20; // Rotate around x-axis
    ref.current.rotation.y -= delta / 35; // Rotate around y-axis
  });

  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      {" "}
      {/* Rotate the group of stars */}
      {/* Render the points representing stars */}
      <Points ref={ref} positions={sphere} stride={4} frustumCulled {...props}>
        {/* Apply point material to stars */}
        <PointMaterial
          transparent
          color="#fff"
          size={props.size ? props.size : 0.0004}
          sizeAttenuation={true}
          depthWrite={true}
        />
      </Points>
    </group>
  );
};

// StarsCanvas component contains the canvas and scene for rendering the stars
const StarsCanvas = ({ width, height, size }) => {
  return (
    <Star width={width} height={height}>
      {/* Create a canvas to render the stars */}
      <Canvas camera={{ position: [0, 0, 1] }}>
        <Suspense fallback={null}>
          {/* Render the Stars component */}
          <Stars size={size} />
        </Suspense>
        <Preload all /> {/* Preload assets for smoother experience */}
      </Canvas>
    </Star>
  );
};

export default StarsCanvas;
const Star = styled.div`
  width: ${(props) => (props.width ? props.width : "full")};
  height: ${(props) => (props.height ? props.height : "100%")};
  position: absolute;
  inset: 0;
  z-index: 0;
`;
